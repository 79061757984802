window.Home = new (function () {

    var that = this;

    /**
     * Init
     */
    this.init = function () {

        let sldrTestimonial = $('.testimonials .wrapTestimonialSlider');

        let sldrTestimonialOpt = {
            autoplay: true,
            dots: true,
            arrows: false,
            infinite: false,
            slidesToShow: 1, // Por defecto, en pantallas más pequeñas muestra 1
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 768, // Para móviles (pantallas menores a 768px)
                    settings: {
                        slidesToShow: 1, // Muestra 1 elemento en móviles
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1200, // Para pantallas entre 768px y 1024px
                    settings: {
                        slidesToShow: 1, // Muestra 2 elementos en tablet
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1600, // Para pantallas entre 768px y 1024px
                    settings: {
                        slidesToShow: 2, // Muestra 2 elementos en tablet
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 99999999, // Para pantallas mayores de 1024px (escritorio)
                    settings: {
                        slidesToShow: 3, // Muestra 3 elementos en escritorio
                        slidesToScroll: 3
                    }
                }
            ]
        }

        sldrTestimonial.each((index, elem) => {
            $(elem).slick(sldrTestimonialOpt);
            $(window).on('resize', function () {
                if (!$(elem).hasClass('slick-initialized')) {
                    return $(elem).slick(sldrTestimonialOpt);
                }
            });
        });

        $('.faqs .question .toggle').on('click', function () {
            const item = $(this).closest('.question'); // Obtener el elemento 'item' padre
            const content = item;

            // Detectar el max-height inicial dependiendo del tamaño de pantalla


            if (content.hasClass('active')) {
                // Contraer a la altura inicial (dependiendo del tamaño de pantalla)
                content.removeClass('active');
                content.css('max-height', getInitialHeight() + 'px');
            } else {
                // Expandir a la altura completa
                content.addClass('active');
                let scrollHeight = content.prop('scrollHeight');
                content.css('max-height', scrollHeight + 'px');
            }
        });

    };

    /**
     * active banners
     */
    this.activeBanners = function () {
        let sldrTG = $('.tg-banners .wrapSlider');

        let sldrOpt = {
            autoplay: false,
            dots: true,
            arrows: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            appendDots: $('.wrapDots'),
            customPaging: function (slider, i) {
                var thumb = $(slider.$slides[i]).data();
                return '<a class="dot">0' + parseInt(i + 1) + '</a>';
            },
        }

        sldrTG.each((index, elem) => {
            $(elem).slick(sldrOpt);
            $(window).on('resize', function () {
                if (!$(elem).hasClass('slick-initialized')) {
                    return $(elem).slick(sldrOpt);
                }
            });
        });
    };


    $(window).resize(function () {
        $('.question').each(function () {
            const content = $(this);
            if (!content.hasClass('active')) {
                // Ajustar la altura solo para los que no están expandidos
                content.css('max-height', getInitialHeight() + 'px');
            }
        });
    });

    function getInitialHeight() {
        if ($(window).width() >= 767 && $(window).width() < 1024) {
            return 220; // Altura inicial en escritorio
        }
        if ($(window).width() >= 1024 && $(window).width() < 1200) {
            return 220; // Altura inicial en escritorio
        }
        if ($(window).width() >= 1200) {
            return 150; // Altura inicial en escritorio
        }
        return 235; // Altura inicial en móviles
    }

})();
